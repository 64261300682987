<template>
  <div v-if="$route.path !== '/privary'">
    <nav class="navbar">
      <!-- fixed z-[2] flex justify-center w-full min-w-[1400px] bg-[${bgC}] -->
      <div class="secondBox max-w-1400">
        <!-- flex justify-between w-full max-w-[1400px] h-[135px] bg-[] -->
        <div class="logo">
          <!-- <img :src="require('@/page/chatchaimobile/components/img/logo.png')" alt="" class="logoImg" /> -->
          <span class="title">Karter Max, S.A. de C.V., SOFOM, E.N.R.</span>
        </div>
        <ul class="nav-links">
          <li>
            <button ref="button1" @click="handleButtonClick('content1', '/')">
              <span class="routeText1" :class="{ 'focus-style': isFocused }"
                >Sobre el producto</span
              >
            </button>
          </li>
          <li>
            <button ref="button2" @click="handleButtonClick('content2', '/')">
              <span class="routeText2">Sobre nosotros</span>
            </button>
          </li>
          <li>
            <button ref="button3" @click="handleButtonClick('content3', '/')">
              <span class="routeText3">Nuestros servicios</span>
            </button>
          </li>
          <li>
            <button @click="handleButtonClick('content4', '/')">
              <span class="routeText4">Acuerdo de privacidad</span>
            </button>
          </li>
          <!-- <li>
            <button ref="button5" @click="handleButtonClick('content5', '/')">
              <span class="routeText5">ความคิดเห็น</span>
            </button>
          </li> -->
          <!-- <li>
            <button ref="button6" @click="handleButtonClick('content6', '/')">
              <span class="routeText6">ติดต่อเรา</span>
            </button>
          </li> -->
          <!-- <li>
            <button ref="button7" @click="handleButtonClick('content7', '/protocol')">
              <span class="routeText7">ข้อตกลงความเป็นส่วนตัว</span>
            </button>
          </li> -->
        </ul>
      </div>
    </nav>
    <div style="height: 130px"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      link1Color: "white",
      isFocused: false, // 新增的变量
    };
  },
  created() {
    // 在组件创建时调用 scrollToSection 方法，设置第一个按钮的背景色
    this.scrollToSection("content1");
    const currentPath = this.$route.path;
    if (currentPath !== "/") {
      this.$router.push("/");
    }
  },
  methods: {
    handleButtonClick(sectionId, route) {
      const buttonRef = `button${sectionId.slice(-1)}`;
      if (this.$refs[buttonRef]) {
        this.scrollToSection(sectionId);
        this.navigateTo(route);
      }
      if (sectionId === "content1") {
        this.scroll1();
      }
      if (sectionId === "content2") {
        this.scroll2();
      }
      if (sectionId === "content3") {
        this.scroll3();
      }
      if (sectionId === "content4") {
        // this.$router.push({ path: "/privary" });
        window.open(
          "https://www.kartermax.com/aL0Zc6aFT/Q3j4k8L9p4a",
          "_blank"
        );
      }
    },
    scroll1() {
      const secondImage = document.querySelector(".imgBox2");
      // secondImage.scrollIntoView({ behavior: "smooth" });
      window.scrollTo({
        top: secondImage.offsetTop - 100,
        behavior: "smooth",
      });
    },
    scroll2() {
      const secondImage = document.querySelector(".imgBox2");
      // secondImage.scrollIntoView({ behavior: "smooth" });
      window.scrollTo({
        top: secondImage.offsetTop + 700,
        behavior: "smooth",
      });
    },
    scroll3() {
      const secondImage = document.querySelector(".imgBox4");
      // secondImage.scrollIntoView({ behavior: "smooth" });
      window.scrollTo({
        top: secondImage.offsetTop - 250,
        behavior: "smooth",
      });
    },

    navigateTo(route) {
      if (route == "/protocol") {
        window.scrollTo(0, 0);
      }
      if (this.$route.path !== route) {
        this.$router.push(route);
      }
    },
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      this.$nextTick(() => {
        const allButtons = [
          "button1",
          "button2",
          "button3",
          // "button4",
          // "button5",
          // "button6",
          // "button7",
        ];

        // 重置所有按钮下 span 元素的颜色和移除 active-link 类
        allButtons.forEach((buttonRef) => {
          const spanElement = this.$refs[buttonRef].querySelector("span");
          if (spanElement) {
            spanElement.classList.remove("active-now");
            spanElement.classList.add("originStyle");
          }
          this.$refs[buttonRef].classList.remove("active-link");
        });

        // 添加当前点击按钮的 active-link 类
        this.$refs[`button${sectionId.slice(-1)}`].classList.add("active-link");

        if (element) {
          // 获取当前按钮下的 span 元素
          const currentButtonSpan =
            this.$refs[`button${sectionId.slice(-1)}`].querySelector("span");
          // 更改当前按钮下的 span 元素的字体颜色
          if (currentButtonSpan) {
            currentButtonSpan.classList.remove("originStyle");
            currentButtonSpan.classList.add("active-now");
          }

          // 应用 setTimeout 和 window.scrollTo 逻辑
          setTimeout(() => {
            // if (sectionId === "content6") {
            //   // 如果是 "content6"，将滚动位置设置为页面底部
            //   window.scrollTo({
            //     top: document.documentElement.scrollHeight,
            //     behavior: "smooth",
            //   });
            // } else {
            //   // 否则，设置为默认偏移值
            //   window.scrollTo({
            //     top: element.offsetTop - 100,
            //     behavior: "smooth",
            //   });
            // }
          }, 50);
        }
      });
    },

    isActive(route) {
      return this.$route.path === route;
    },
    setFocus(value) {
      this.isFocused = value;
    },
  },
};
</script>

<style scoped>
.navbar {
  position: fixed;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

.secondBox {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 130px;
  padding: 0 20px;
  background-color: #0baf60;
}

.logo {
  font-weight: bold;
  color: black;
  display: flex;
  /* 让 Logo 内容成为一个 flex 容器，使图片和文字水平排列 */
  align-items: center;
  /* 垂直居中对齐 */
}

.logoImg {
  width: 26px;
  height: 42px;
}

.title {
  padding-left: 20px;
  width: 500px;
  height: 39px;
  font-size: 20px;
  font-family: Inter, Inter;
  font-weight: 500;
  color: #ffffff;
  line-height: 38px;
}

.routeText1,
.routeText2,
.routeText3,
.routeText4,
.routeText5,
.routeText6,
.routeText7 {
  height: 21px;
  font-size: 17px;
  font-family: Inter, Inter;
  font-weight: 500;
  color: #ffffff;
  line-height: 21px;
  text-align: center;
  /* 将文本水平居中 */
  white-space: nowrap;
}

.routeText1 {
  width: 100px;
}

.routeText2 {
  width: 100px;
}

.routeText3 {
  width: 100px;
}

.routeText4 {
  width: 100px;
}

.routeText5 {
  width: 130px;
}

.routeText6 {
  width: 100px;
}

.routeText7 {
  width: 200px;
}

.nav-links {
  list-style: none;
  display: flex;
}

.nav-links li {
  height: 49px;
  padding: 5px;
  height: 49px;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  /* 添加这一行 */
  padding-left: 90px;
}

.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4 {
  text-decoration: none;
  text-transform: uppercase;
  /* 将链接文字转为大写 */
  font-size: 18px;
  font-family: SF Pro-Semibold;
}

button {
  height: 40px;
  background-color: transparent;
  /* 设置按钮背景为透明 */
  padding-left: 14px;
  /* 左边距 */
  padding-right: 14px;
  /* 右边距 */
}

/* .active-link {
  color: #1b3764;
  background-size: 100% 100%; 
  background-repeat: no-repeat;
} */

.active-link {
  background-color: #ffffff;
  border-radius: 10px;
  /* 设置背景色 */
}

.active-link span {
  color: #0baf60;
}

.active-now {
  width: 69px;
  height: 24px;
  font-size: 19px;
  font-family: Cardo, Cardo;
  font-weight: bold;
  color: #1b3764;
  line-height: 24px;
}

.originStyle {
  width: 650px;
  height: 21px;
  font-size: 17px;
  font-family: Inter, Inter;
  font-weight: 500;
  color: #ffffff;
  line-height: 21px;
}
.title {
  /* border: 1px solid red; */
  width: 400px;
}
.secondBox {
  padding: 0 100px 0 120px;
}
</style>
